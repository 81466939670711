import {
  Box,
  Grid,
  InputLabel,
  Typography,
  ListItemButton,
  Button,
  Divider,
  Skeleton,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../Main/Components/Custom/CustomInput";
import BookingData from "../../Assets/Dataa/BookingData";
import anime from "../../Assets/anime/done.json";
import Lottie from "react-lottie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { createReservationOffline, createReservationOfflineDeposit, createWaitlistOffline, getRestaurantDetails } from "../../api";
import Loader from "../../Main/Components/Common/Loader";
import logo from "../../Assets/logo/logo.svg";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoClockFill } from "react-icons/go";
import queryString from "query-string";
import { useSnackbar } from "notistack";
import DietaryOptions from "../../Main/Components/Common/DietaryOptions";
import PaystackPaymentButton from "../../Main/Components/Common/PaystackPaymentButton";
import { Helmet } from "react-helmet-async";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "@mui/material/Modal";
import { PiClockUserThin } from "react-icons/pi";

function generateUniqueCode(prefix = "web_") {
  const timestamp = Date.now().toString(); 
  const randomChars = Math.random().toString(36).substring(2, 6).toUpperCase(); 
  return `${prefix}${timestamp}${randomChars}`;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {lg:400, md:400, sm:350, xs:350},
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "10px",
    maxHeight:'70vh',
  overflowY:'scroll'
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: anime,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const InformationWidget = ({}) => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [Dietary, setDietary] = useState("");
  const [occasion, setOccasion] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
    const [openWaitlist, setOpenWaitlist] = useState(false)
  const [rulesChecked, setRulesChecked] = useState(false);
  const { name: restaurantName } = useParams();
  const queryParams = queryString.parse(location.search);
  const {
    PartySize: guest,
    time,
    ReservationDay: date,
    deposit,
    floor,
    waitlist
  } = queryParams;



  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleCreateReservation = async () => {
    setIsLoading(true);
    const groupMemberDietaryRestriction = Dietary?.join(", ");
    const newDeposit = deposit * guest;

  const ref = generateUniqueCode();

    await createReservationOfflineDeposit(
      restaurant?.RestaurantId,
      floor,
      date,
      time,
      firstName,
      lastName,
      phoneNumber,
      email,
      parseFloat(guest),
      ref,
      occasion,
      groupMemberDietaryRestriction,
      specialRequest,
      newDeposit,
      false,
      newDeposit * 100,
    )
      .then((res) => {

        if (res?.data?.status) {
window.location.href=res?.data?.result?.authorization_url
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err)
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };
  const handleWaitlistReservation = async () => {
    setIsLoading(true);
    const groupMemberDietaryRestriction = Dietary?.join(", ");
    const newDeposit = deposit * guest;

    await createWaitlistOffline(
      restaurant?.RestaurantId,
      floor,
      date,
      time,
      firstName,
      lastName,
      phoneNumber,
      email,
      parseFloat(guest),
      occasion,
      groupMemberDietaryRestriction,
      specialRequest,
      null,
  true
    )
      .then((res) => {
        if (res?.data?.status) {
    setOpenWaitlist(true)
    
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err)
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };


  const handleCreateReservation2 = async () => {
    setIsLoading(true);
    const groupMemberDietaryRestriction = Dietary?.join(", ");

    await createReservationOffline(
      restaurant?.RestaurantId,
      floor,
      date,
      time,
      firstName,
      lastName,
      phoneNumber,
      email,
      guest,
      occasion,
      groupMemberDietaryRestriction,
      specialRequest,
      deposit,
      null,
      true
    )
      .then((res) => {
        if (res?.data?.status) {
          navigate(`/reservation/booked/${res?.data?.result[0]?.BookingId}`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err)
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };

  const [loading, setLoading] = useState(false);
  const [restaurant, setRestaurant] = useState(null);

  const handleGetRestaurantDetails = async () => {
    setLoading(true);
    await getRestaurantDetails(restaurantName)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        if (res?.data?.status) {
          setRestaurant(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
      });
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);

  const [mobileError, setMobileError] = useState("");

  const validateMobileNumber = (number) => {
    const mobilePattern = /^[0-9]{8,15}$/;
    return mobilePattern.test(number);
  };
  const handleMobileChange = (e) => {
    const mobileValue = e.target.value;
    setPhoneNumber(mobileValue);

    if (!validateMobileNumber(mobileValue)) {
      setMobileError("Please enter a valid mobile number.");
    } else {
      setMobileError("");
    }
  };

  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    if (!validateEmail(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };




  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {isLoading && <Loader />}
      {isSuccessful ? (
        <>
          <Box
            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Lottie options={defaultOptions} width={400} height={200} />
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              Reservation Modified
            </Typography>
            <Typography>
              Please check your inbox for a confirmation email.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box align="center" sx={{ pt: 4 }}>
            <img src={logo} width={90} />
            <Typography sx={{ mt: 2, fontWeight: 700, fontSize: "16px" }}>
              Complete Reservation
            </Typography>
          </Box>
          <Box
            sx={{
              margin: "0 auto",
              mt: 4,
              width: {
                xl: "80%",
                lg: "80%",
                md: "90%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Box sx={{ p: 3, boxSizing: "border-box" }}>
                  <Typography sx={{ fontWeight: 600, mb: 2 }}>
                    Personal Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={6}>
                      <InputLabel required sx={{ fontSize: "12px" }}>
                        First Name
                      </InputLabel>
                      <CustomInput
                        fullWidth
                        small
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <InputLabel required sx={{ fontSize: "12px" }}>
                        Last Name
                      </InputLabel>
                      <CustomInput
                        fullWidth
                        small
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <InputLabel  required sx={{ fontSize: "12px" }}>
                        Phone Number
                      </InputLabel>
                      <CustomInput
                        fullWidth
                        small
                        value={phoneNumber}
                        onChange={handleMobileChange}
                        error={mobileError}
                        helperText={mobileError}
                        type="number"
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <InputLabel required sx={{ fontSize: "12px" }}>
                        {" "}
                        Email Address
                      </InputLabel>
                      <CustomInput
                        fullWidth
                        small
                        value={email}
                        onChange={handleEmailChange}
                        error={emailError}
                        helperText={emailError}
                      />
                    </Grid>
                  </Grid>

                  {deposit > 0 && (
                    <Box
                      sx={{
                        mt: 3,
                        borderRadius: "8px",
                        border: "1px solid #d7d7d7",
                      }}
                    >
                      <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                        <Typography color="primary" sx={{ fontWeight: 600 }}>
                          Reservation Deposit
                        </Typography>
                      </Box>
                      <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              textTransform: "uppercase",
                            }}
                          >{`${restaurantName} * ${guest}`}</Typography>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            ₦{parseFloat(deposit).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ p: 2, borderBottom: "1px solid #d7d7d7" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            Total
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            ₦{parseFloat(deposit * guest).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            Amount Due
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 500 }}
                          >
                            ₦{parseFloat(deposit * guest).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          bgcolor: "#bc172f1a",
                          borderRadius: "0px 0px 8px 8px",
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration: "underline",
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          Disclaimer
                        </Typography>
                        <Typography sx={{ fontSize: "10px" }}>
                          This deposit will be deducted from your final bill
                          after the reservation has been concluded. In the event
                          you need to cancel your reservation, please ensure you
                          do so at least 12 hours before the reservation time,
                          and the deposit will be refunded to your bank account.
                          You will receive a reservation reminder 13 hours
                          before your reservation time.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Typography sx={{ fontWeight: 600, mb: 1, mt: 4 }}>
                    Lets help you plan your visit
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#333" }}>
                    To aid {restaurantName} Restaurant in organizing your visit,
                    kindly provide answers to the questions below.
                  </Typography>
                  <Box
                    sx={{
                      mt: 2,
                      borderRadius: "10px",
                      border: "1px solid #d7d7d7",
                      p: 2,
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                      What occasion are you celebrating?
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        {BookingData?.map((item, index) => {
                          const isSelected = item?.title === occasion;
                          return (
                            <Grid item lg={6} xs={6} key={index}>
                              <ListItemButton
                                selected={isSelected}
                                onClick={() => setOccasion(item?.title)}
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  columnGap: 2,
                                  p: 2,
                                  border: "1px solid #d7d7d7",
                                  borderRadius: "8px",
                                  ...(isSelected && {
                                    border: "1px solid #BC172F",
                                  }),
                                }}
                              >
                                <img
                                  src={isSelected ? item?.icon2 : item?.icon1}
                                />
                                <Typography
                                  color={isSelected && "primary"}
                                  sx={{ fontSize: "12px" }}
                                >
                                  {item?.title}
                                </Typography>
                              </ListItemButton>
                            </Grid>
                          );
                        })}
                      </Grid>

                      <Box sx={{ mt: 3 }}>
                        <DietaryOptions
                          updateDietary={(value) => setDietary(value)}
                        />
                        {/* <CustomInput
                          value={Dietary}
                          onChange={(e) => setDietary(e.target.value)}
                          placeholder="Your  input here"
                          multiline
                          fullWidth
                          margin="dense"
                          minRows={3}
                        /> */}
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Do have any special request?
                        </Typography>

                        <CustomInput
                          value={specialRequest}
                          onChange={(e) => setSpecialRequest(e.target.value)}
                          placeholder="Your  input here"
                          multiline
                          fullWidth
                          margin="dense"
                          minRows={2}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box sx={{ p: 3, boxSizing: "border-box" }}>
                  <Box
                    sx={{
                      border: "1px solid #d7d7d7",
                      p: 2,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography sx={{ fontWeight: 700 }}>
                      Reservation Details
                    </Typography>
                    <Typography sx={{ mt: 2 }}>{restaurantName}</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                          mt: 1,
                        }}
                      >
                        <BsCalendar2CheckFill style={{ fontSize: "14px" }} />
                        <Typography sx={{ fontSize: "12px" }}>
                          {formatDate(date)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                          mt: 0.4,
                        }}
                      >
                        <BsFillPeopleFill style={{ fontSize: "14px" }} />
                        <Typography
                          sx={{ fontSize: "12px" }}
                        >{`${guest} Guest`}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                          mt: 0.4,
                        }}
                      >
                        <GoClockFill style={{ fontSize: "14px" }} />
                        <Typography sx={{ fontSize: "12px" }}>
                          {time}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 3,
                      p: 2,
                      border: "1px solid #d7d7d7",
                      borderRadius: "8px",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                        Cancellation policy
                      </Typography>
                      <Typography
                        sx={{ fontSize: "10px", mt: 1, textAlign: "justify" }}
                      >
                        We understand that circumstances can change, and if you
                        need to cancel, there won't be any charges. However, we
                        kindly request that you notify us at least 24 hours
                        prior to your scheduled time.
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
                      Reservation being held for {time}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    {loading ? (
                      <>
                        <Skeleton width={"100%"} height={100} />
                      </>
                    ) : (
                      <>
                        {restaurant?.Experience?.HouseRules && (
                          <>
                            <Box
                              sx={{
                                mt: 1,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                checked={rulesChecked}
                                onClick={handleOpen}
                              />{" "}
                              <Typography sx={{ fontWeight: 500 }}>
                                Click to view and accept the house rules terms.
                              </Typography>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  {
                    waitlist === "true" ? (
                      <Button      variant="contained"
                      fullWidth
                      sx={{ mt: 5, borderRadius: "50px", py: 2 }} onClick={handleWaitlistReservation}>Join the waitlist</Button>
                    ):(
                      <>
                           {deposit > 0 ? (
                    <>
                      <Box
                        sx={{
                          p: 2,
                          border: "1px solid #d7d7d7",
                          borderRadius: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            pb: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontWeight: 400 }}>
                            Payment Method
                          </Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            Pay Online
                          </Typography>
                        </Box>
                      </Box>

               
                  
                          <Button

                            disabled={emailError || !email ||
                      mobileError ||
                      !phoneNumber ||
                      !firstName ||
                      !lastName }
                            onClick={handleCreateReservation}
                            variant="contained"
                            fullWidth
                            sx={{ mt: 5, borderRadius: "10px", py: 2 }}
                          >
                            Make Payment
                          </Button>
              
                 
                    </>
                  ) : (
                    <Button
                      disabled={
                        !firstName ||
                        !lastName ||
                        !email ||
                        !phoneNumber ||
                        (restaurant?.Experience?.HouseRules && !rulesChecked)
                      }
                      onClick={handleCreateReservation2}
                      variant="contained"
                      fullWidth
                      sx={{ mt: 5, borderRadius: "50px", py: 2 }}
                    >
                      Complete Reservation
                    </Button>
                  )} 
                      </>
                    )
                  }

            

                  {/* first_name, last_name, email, amount, onSuccessAction, label  */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            House Rules
          </Typography>
          <Divider sx={{ mt: 1 }} />
          <Box sx={{ mt: 2, mx: -3 }}>
            <ReactQuill
              value={restaurant?.Experience?.HouseRules}
              readOnly={true}
              theme={null} // Disable theme
              modules={{ toolbar: false }}
              style={{
                fontFamily: "outfit",

                padding: "0%",
              }}
              className="ql-editor" // Apply custom CSS class
            />
          </Box>

          <Box align="right" sx={{ mt: 3 }}>
            <Button
              variant="contained"
              onClick={(e) => {
                setRulesChecked(true);
                handleClose();
              }}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Modal>


      <Modal
        open={openWaitlist}
     
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       
          <Box sx={{ mt: 2,  }} align="center">
            <PiClockUserThin style={{fontSize:'60px', color:'#FFA500 '}} />
<h3 style={{fontFamily:'optima', textAlign:'center', fontSize:'30px'}}>You’re Now on the Waitlist</h3>
<Typography sx={{mt:-3}}>We’re preparing to accommodate you. Please wait for further updates.</Typography>
          </Box>

          <Box align="center" sx={{ mt: 5 }}>
            <Button
              variant="contained"
              sx={{width:'230px', height:'45px', borderRadius:'8px'}}
              onClick={(e) => {
      navigate(`/${restaurantName}/reservation`)
              }}
            >
       Thank You
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default InformationWidget;

function formatDate(inputDate) {
  // Create a Date object
  const date = new Date(inputDate);

  // Define an array of day and month names
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day of the week, month, and day of the month
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

  return formattedDate;
}
